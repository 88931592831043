var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dashboard"
  }, [_c('div', {
    staticClass: "row p-2 m-1"
  }, [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": true
    }
  }, [_c('b-card-body', {
    staticClass: "p-0 clearfix"
  }, [_c('i', {
    staticClass: "fa fa-line-chart bg-success p-4 font-2xl mr-3 float-left"
  }), _vm.order_paid_rp ? _c('div', {
    staticClass: "h6 text-info mb-0 pt-3"
  }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.order_paid_rp.total_payment, _vm.order_paid_rp.currency_code)) + " ")]) : _c('div', {
    staticClass: "h6 text-info mb-0 pt-3"
  }, [_c('b-spinner', {
    attrs: {
      "type": "grow",
      "label": "Loading..."
    }
  })], 1), _c('div', {
    staticClass: "text-muted text-uppercase font-xs"
  }, [_vm._v(" Income ")])])], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": true
    }
  }, [_c('b-card-body', {
    staticClass: "p-0 clearfix"
  }, [_c('i', {
    staticClass: "fa fa-bell bg-danger p-4 font-2xl mr-3 float-left"
  }), _vm.order_paid_rp ? _c('div', {
    staticClass: "h6 text-info mb-0 pt-3"
  }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.order_cancel_rp.total_payment, _vm.order_cancel_rp.currency_code)) + " ")]) : _c('div', {
    staticClass: "h6 text-info mb-0 pt-3"
  }, [_c('b-spinner', {
    attrs: {
      "type": "grow",
      "label": "Loading..."
    }
  })], 1), _c('div', {
    staticClass: "text-muted text-uppercase font-xs"
  }, [_vm._v(" Failed Income ")])])], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": true
    }
  }, [_c('b-card-body', {
    staticClass: "p-0 clearfix"
  }, [_c('i', {
    staticClass: "fa fa-line-chart bg-success p-4 font-2xl mr-3 float-left"
  }), _vm.order_paid_dollar ? _c('div', {
    staticClass: "h6 text-info mb-0 pt-3"
  }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.order_paid_dollar.total_payment, _vm.order_paid_dollar.currency_code)) + " ")]) : _c('div', {
    staticClass: "h6 text-info mb-0 pt-3"
  }, [_c('b-spinner', {
    attrs: {
      "type": "grow",
      "label": "Loading..."
    }
  })], 1), _c('div', {
    staticClass: "text-muted text-uppercase font-xs"
  }, [_vm._v(" Income ")])])], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": true
    }
  }, [_c('b-card-body', {
    staticClass: "p-0 clearfix"
  }, [_c('i', {
    staticClass: "fa fa-bell bg-danger p-4 px-5 font-2xl mr-3 float-left"
  }), _vm.order_paid_dollar ? _c('div', {
    staticClass: "h6 text-danger mb-0 pt-3"
  }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.order_cancel_dollar.total_payment, _vm.order_cancel_dollar.currency_code)) + " ")]) : _c('div', {
    staticClass: "h6 text-info mb-0 pt-3"
  }, [_c('b-spinner', {
    attrs: {
      "type": "grow",
      "label": "Loading..."
    }
  })], 1), _c('div', {
    staticClass: "text-muted text-uppercase font-xs"
  }, [_vm._v(" Failed Income ")])])], 1)], 1), _c('div', {
    staticClass: "col-sm-12"
  }, [_c('div', {
    staticClass: "card shadow-sm rounded p-1"
  }, [_c('ColumnChart')], 1)]), _c('div', {
    staticClass: "col-sm-6"
  }, [_c('div', {
    staticClass: "card shadow-sm rounded p-1"
  }, [_c('DonutChart')], 1)]), _c('div', {
    staticClass: "col-sm-6"
  }, [_c('div', {
    staticClass: "card shadow-sm rounded p-1"
  }, [_c('readingpopular')], 1)])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }