const BookPath = {
  path: '/ebooks',
  name: 'ebooks',
  component: () => import('../views/ebooks/ebooks.vue'),
};
const BookCreatePath = {
  path: '/ebooks/create',
  name: 'ebooksCreate',
  component: () => import('../views/ebooks/newebooks.vue'),
};
const BookEditPath = {
  path: '/ebooks/edit/:id',
  name: 'ebooksEdit',
  component: () => import('../views/ebooks/edit/editEbooks.vue'),
};
// newspaper
const NewspaperPath = {
  path: '/newspaper',
  name: 'newspaper',
  component: () => import('../views/newspaper/newspaper.vue'),
};
const NewspaperCreatePath = {
  path: '/newspaper/create',
  name: 'newspaperCreate',
  component: () => import('../views/newspaper/newnewspaper.vue'),
};
const NewspaperEditPath = {
  path: '/newspaper/edit/:id',
  name: 'newspaperEdit',
  component: () => import('../views/newspaper/edit/editNewspaper.vue'),
};

// audio
const AudiobookPath = {
  path: '/audio',
  name: 'AudiobookPath',
  component: () => import('../views/audio_book/audioBooks.vue'),
};

const AudioCreatePath = {
  path: '/audio/create',
  name: 'newAudioBook',
  component: () => import('../views/audio_book/newAudioBook.vue'),
};
const AudioEditPath = {
  path: '/audio/edit/:id',
  name: 'editAudioBook',
  component: () => import('../views/audio_book/edit/editAudioBook.vue'),
};
const AudioEditChapterPath = {
  path: '/audio/editChapter/:id',
  name: 'editAudioBookChapter',
  component: () => import('../views/audio_book/edit/editAudioChapter.vue'),
};

//magaizne
const MagazinePath = {
  path: '/magazine',
  name: 'magazine',
  component: () => import('../views/magazine/magazine.vue'),
};
const MagazineCreatePath = {
  path: '/magazine/create',
  name: 'magazineCreate',
  component: () => import('../views/magazine/newmagazine.vue'),
};
const MagazineEditPath = {
  path: '/magazine/edit/:id',
  name: 'magazineEdit',
  component: () => import('../views/magazine/edit/editMagazine.vue'),
};
// Bulk Menu
const BulkCreate = {
  path: '/items/bulk/create',
  name: 'bulkCreateItems',
  component: () => import('../views/bulk/bulkCreate.vue'),
};
const BulkCreateItemsPublisher = {
  path: '/items/p/bulk/create',
  name: 'bulkCreateItemsPublisher',
  component: () => import('../views/bulk/bulkCreatePublisher'),
};
const BulkCreateAudio = {
  path: '/items/bulk/create/audio',
  name: 'bulkCreateAudio',
  component: () => import('../views/bulk/bulkCreateAudio.vue'),
};
const BulkUpdate = {
  path: '/items/bulk/update',
  name: 'bulkUpdateItems',
  component: () => import('../views/bulk/bulkUpdate.vue'),
};
const BulkUpload = {
  path: '/items/bulk/upload',
  name: 'bulkUploadItems',
  component: () => import('../views/bulk/bulkUpload.vue'),
};
const BulkUploadAudio = {
  path: '/items/bulk/uploadAudio',
  name: 'bulkUploadItemsAudio',
  component: () => import('../views/bulk/bulkUploadAudio.vue'),
};
const BulkUpdateOfferPremium = {
  path: '/items/bulk/update/premium',
  name: 'bulkUpdateOfferPremium',
  component: () => import('../views/bulk/bulkUpdateOffersPremium.vue'),
};
// End of Bulk Menu

const NarratorPath = {
  path: '/narrator',
  name: 'narrator',
  component: () => import('../views/narrator/narrator.vue'),
};
const NarratorCreatePath = {
  path: '/narrator/create',
  name: 'narratorCreate',
  component: () => import('../views/narrator/narratorCreate.vue'),
};
const NarratorUpdatePath = {
  path: '/narrator/edit/:id',
  name: 'narratorUpdate',
  component: () => import('../views/narrator/narratorUpdate.vue'),
};

const AuthorPath = {
  path: '/author',
  name: 'author',
  component: () => import('../views/author/author.vue'),
};
const AuthorCreatePath = {
  path: '/author/create',
  name: 'authorCreate',
  component: () => import('../views/author/authorCreate.vue'),
};
const AuthorUpdatePath = {
  path: '/author/edit/:id',
  name: 'authorUpdate',
  component: () => import('../views/author/authorUpdate.vue'),
};
const AuthorPopularPath = {
  path: '/author/popular',
  name: 'authorPopular',
  component: () => import('../views/author/popular.vue'),
};
// detail items
const DetailItems = {
  path: '/items/detail/:id',
  name: 'detailItems',
  component: () => import('../views/ebooks/ebookDetail.vue'),
};
const EditCoverItemPath = {
  path: '/items/edit/:id/cover',
  name: 'editCoverItems',
  component: () => import('../views/ebooks/edit/editCover.vue'),
};
const EditPreviewItemPath = {
  path: '/items/edit/:id/preview',
  name: 'editPreviewItems',
  component: () => import('../views/ebooks/edit/editPreview.vue'),
};
const AxItemPath = {
  path: '/ax/items',
  name: 'axItems',
  component: () => import('../views/ax/list.vue'),
};
const UpdateAxItemPath = {
  path: '/ax/item/:id',
  name: 'update-single-ax',
  component: () => import('../views/ax/edit-single.vue'),
};
const CreateAxItemPath = {
  path: '/ax/items/create',
  name: 'create-single-ax',
  component: () => import('../views/ax/create-single.vue'),
};
const PremiumAxPath = {
  path: '/ax/premium-ax',
  name: 'premium-ax-list',
  component: () => import('../views/ax/premium-ax-list.vue'),
};
const UpdatePremiumAxPath = {
  path: '/ax/premium-ax/:id',
  name: 'update-premium-ax',
  component: () => import('../views/ax/edit-premium.vue'),
};
const CreatePremiumAxPath = {
  path: '/ax/premium/create',
  name: 'create-premium-ax',
  component: () => import('../views/ax/create-premium.vue'),
};
const CategoryPath = {
  path: '/category',
  name: 'category',
  component: () => import('../views/category/categoryList.vue'),
};
const EditCategoryPath = {
  path: '/category/edit/:id',
  name: 'category-edit',
  component: () => import('../views/category/categoryEdit.vue'),
};
const AddCategoryPath = {
  path: '/category/add',
  name: 'category-add',
  component: () => import('../views/category/categoryAdd.vue'),
};

const brandItemAx = {
  path: '/ax/items/brand-ax',
  name: 'brand-ax',
  component: () => import('../views/ax/brand-ax.vue'),
};
const createBrandItemAx = {
  path: '/ax/items/brand-ax/create',
  name: 'create-brand-ax',
  component: () => import('../views/ax/brand-ax-create.vue'),
};
const updateBrandItemAx = {
  path: '/ax/items/brand-ax/edit/:id',
  name: 'update-brand-ax',
  component: () => import('../views/ax/brand-ax-edit.vue'),
};
const UploadSingleAx = {
  path: '/ax/items/bulk-upload-single',
  name: 'update-ax-single',
  component: () => import('../views/ax/bulk-upload.vue'),
};
const UploadPremiumAx = {
  path: '/ax/items/bulk-upload-premium',
  name: 'update-ax-premium',
  component: () => import('../views/ax/bulk-upload-premium.vue'),
};
const UploadBrandAx = {
  path: '/ax/items/bulk-upload-subscription',
  name: 'update-ax-subscription',
  component: () => import('../views/ax/bulk-upload-subscription.vue'),
};
const HomepageRecommedationPath = {
  path: '/content-recommedation',
  name: 'recommendationhomepage',
  component: () => import('../views/recommendationhomepage/content-recommedation.vue'),
};
const HomepageRecommedationIdPath = {
  path: '/content-recommedation/detail/:id',
  name: 'recommendationhomepagedetail',
  props: route => ({ category: Number(route.params.category) }),
  component: () => import('../views/recommendationhomepage/detail.vue'),
};
module.exports = {
  BookPath,
  BookCreatePath,
  BookEditPath,
  MagazinePath,
  MagazineCreatePath,
  MagazineEditPath,
  NewspaperPath,
  NewspaperCreatePath,
  NewspaperEditPath,
  BulkCreate,
  BulkCreateAudio,
  BulkUpdate,
  BulkUpload,
  BulkUploadAudio,
  BulkUpdateOfferPremium,
  BulkCreateItemsPublisher,
  AuthorPath,
  AuthorCreatePath,
  AuthorUpdatePath,
  AuthorPopularPath,
  NarratorPath,
  NarratorCreatePath,
  NarratorUpdatePath,
  AudiobookPath,
  DetailItems,
  AxItemPath,
  UpdateAxItemPath,
  CreateAxItemPath,
  PremiumAxPath,
  UpdatePremiumAxPath,
  CreatePremiumAxPath,
  CategoryPath,
  EditCategoryPath,
  AddCategoryPath,
  EditCoverItemPath,
  EditPreviewItemPath,
  AudioEditPath,
  AudioEditChapterPath,
  AudioCreatePath,
  brandItemAx,
  createBrandItemAx,
  updateBrandItemAx,
  UploadSingleAx,
  UploadPremiumAx,
  UploadBrandAx,
  HomepageRecommedationPath,
  HomepageRecommedationIdPath
};
