import API from "../plugins/http";
const Orders = {
  namespaced: true,
  state: {
    items: [],
    subscription: [],
    isLoading: false,
    isSubLoading: false,
    errorMessage: '',
    successMessage:'',
    aggregates: [],
    isError: false,
    isSubError: false,
    subErrorMessage: null,
  },
   actions: {
    async fetchSingle({ commit }, state) {
      try {
        commit("setLoading", true);
        commit("setItems", []);
        const response = await API.get(
          `report-content?from=${state.from}&to=${state.to}&offer_type=${state.offer_type}&page=${state.page}&limit=${state.limit}`
        );
        let data = response.data.data;
        commit("setItems", data);
        commit("setLoading", false);
      } catch (error) {
        let response = error.response;
        commit("setError", true);
        commit("setLoading", false);
        if (response && response.status == 403) {
          let err = response.data.message;
          commit("setErrorMessage", err);
        } else {
          commit("setErrorMessage", error.response);
        }
        return error;
      }
    },
    async fetchCategoriRecomendation({ commit }, state) {
      try {
        commit("setLoading", true);
        commit("setItems", []);
        const response = await API.get(`layouts/${state}`);
        let data = response.data.data;
        commit("setItems", data);
        commit("setLoading", false);
      } catch (error) {
        let response = error.response;
        commit("setError", true);
        commit("setLoading", false);
        if (response && response.status == 403) {
          let err = response.data.message;
          commit("setErrorMessage", err);
        } else {
          commit("setErrorMessage", error.response);
        }
        return error;
      }
    },
    async moveUptable ({commit},payload) {
      commit('setLoading', false);
      const offers = {
        offers: payload
      };
      try {
        const response = await API.put('layouts/offer/sort-priority',offers);
        commit('setLoading', false);
        return response
      } catch (error) {
        console.log(error.message);
      }

    },
    async deleteItems({ commit }, payload) {
      commit('setLoading', true);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      const data = {
        offers: payload
      };
      try {  
        const response = await API.delete('layouts/offer',{data:data});
        if (response.status === 200) { 
          commit('setSuccessMessage', "Success Deleted");
        }
        commit('setLoading', false);
      } catch (error) {
        console.log(error.message);
        const response = error.response;
        let errMsg = "Error, please try again";
        if (response.status === 404) errMsg = response.statusText;
        if (response.status === 422) {
          errMsg = response.statusText;
          errMsg += ' REQUIRED ';
          errMsg += (response.data.errors ?? []).map(item => item.param).join(', ');
        }
        if (response.status === 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setError', true);
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
    },
    async generateLayout({ commit }, payload) {
      commit('setLoading',true);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      const data = {
        layout_id:payload.layout_id,
        item_type:payload.item_type
      }
      try {
        const response = await API.post('layouts/generate-offer',data);
        commit('setItems', response.data.data)
        if (response.status === 200) { 
         // commit('setSuccessMessage', "Add Item Success");
        }
        commit('setLoading', false);
      } catch (error) {
        console.log(error);
      }
    },
    async allUpdateData({commit},payload) {  
      commit('setLoading', true);
      
      const data = {
        "offers":payload
      }
      try {
        const response = await API.post('layouts/offer/sort-priority',data);
       if (response.status === 200) { 
          commit('setSuccessMessage', "Update has been success");
          commit('setLoading', false);
          return response.data; 
        }
    
      } catch (error) {
        console.log(error.message);
     
        commit('setErrorMessage',  this.errorMessage);
        const response = error.response;
        if (response.status === 422) {
          commit('setError', true);
          if(response.data.errors ===`offer_id ${data.offer_id} has been used for layout_id ${data.layout_id}`) {
            commit('setError', true);
            this.errorMessage = "The data already exists in the table."
            commit('setErrorMessage',this.errorMessage);
          } 
        }
        if (response.status === 500) {
           return response.data.errors ?? response.status;
        }
      }
    },
    async getSearchOffer({ commit }, query) {
      commit('setLoading', false);
      try {
        const response = await API.get(`layouts/search-offer?&limit=10&type=${query.param}&q=${query.qry}`);
        return response
      } catch (error) {
        return error
      }
    },
    async fetchSubscription({ commit }, state) {
      try {
        commit("setSubscription", []);
        const response = await API.get(
          `report-content?from=${state.from}&to=${state.to}&offer_type=${state.offer_type}&page=${state.page}&limit=${state.limit}`
        );
        let data = response.data.data;
        commit("setSubscription", data);
      } catch (error) {
        let response = error.response;
        commit("setSubError", true);
        commit("setSubLoading", false);
        if (response && response.status == 403) {
          let err = response.data.message;
          commit("setSubErrorMessage", err);
        } else {
          commit("setSubErrorMessage", error.response);
        }
        return error;
      }
    }
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setSubscription(state, item) {
      state.subscription = item;
    },
    deleteItem(state, index) {
      state.items.splice(index, 1);
    },
    addArrayItems(state,items) {
      items.forEach((event)=> {
        state.items.push(...event.offers);
      }) 
    },
    SET_ITEMS(state, newItems) {
      state.items = newItems;
    },
    SET_SORT_PRIORITY(state, { datadrag, newIndex }) {
      const oldIndex = state.items.findIndex(item => item.offer_id === datadrag.offer_id);
      state.items.splice(oldIndex, 1);
      state.items.splice(newIndex, 0, datadrag);
    },  
    setLoading(state, data) {
      state.isLoading = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setSubLoading(state, data) {
      state.isSubLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setSubError(state, data) {
      state.isSubError = data;
    },
    setSubErrorMessage(state, data) {
      state.subErrorMessage = data;
    },
  },
};

export default Orders;
