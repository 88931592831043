<template>
  <div class="row">
    <div class="col-sm-12 shadow-md p-2">
      <h4 class="h4 text-black text-center"> Transaction</h4>
      <apexchart
        type="column"
        height="350"
        :options="chartOptionsSales1"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import API from "../../plugins/http";
export default {
  name: "ColumnChart",
  data() {
    return {
      series: [],
      chartOptionsSales1: {
        noData: {
          text: 'Loading...'
        },
      },
      data: [],
      datapaid: [],
      dataunpaid: [],
      showlabel:true,
    };
  },
  mounted() {
    this.actionLoadChartWihtgroup();
  },
  methods: {
    showLabel() {
      this.chartOptionsSales1.dataLabels.enabled = this.showLabel ? false : true;
      console.log(this.chartOptionsSales1.dataLabels.enabled)
    },
    async actionLoadChartWihtgroup() {
      let allsales = await this.actionGetSales();
      let  salesPaid = await this.actionGetSalesPaid();
      let allsalesunpaid = await this.actionGetSalesunPaid();
      if(allsales.label) {
        this.chartOptionsSales1 ={
          chart: {
            height: 380,
            type: "line",
            stacked: false,
            events: {
              click:function() {
                // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
              },
            }
          },
          dataLabels: {
            enabled: this.showlabel,
            enabledOnSeries:[0],//[1,2]
            formatter: value => {
              return this.formatPriceBasic(value);
            }
          },
          stroke: {
            width: [4, 4, 4]
          },
          xaxis: {
            categories: allsales.label
          },
          // colors: ['#0060AF','#ffec2e','#ff06dc', '#43d14f'],
          colors: ['#ff06dc', '#ffec2e','#59a5e3','#ff06dc'],
          yaxis: [
            {
              title:'Amount',
              position:'right',
              labels: {
                show: true,
                formatter: value => {
                  return this.formatPriceBasic(value);
                }
              }
            }
          ],
        }
      }
      
      this.series= [
        {
          name: "Paid",
          type: "line",
          data: salesPaid?.data
        },
        {
          name: "Unpaid",
          type: "column",
          data: allsalesunpaid?.data
        },
        {
          name :'Orders',
          type:"column",
          data :allsales.data
        }
      ];
     
    },  
    async actionGetSales() {
      const sales = await API
        .get("chart-dashboard/orders")
      if(sales) {
        return {
          label: sales.data.data.map(a => {
            return moment(a.day).format("DD MMM");
          }),
          data: sales.data.data.map(a => {
            return this.formatPrice(a.final_amount,"IDR")// this.formatPriceBasic(a.final_amount)
          })
        }
      }

      return {
        label: [],
        data: []
      }
    },
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async actionGetSalesPaid() {
      try {
        const PAID = await 
          API
          .get("chart-dashboard/orders/paid")
  
        if (PAID) {
          console.log(PAID)
          return {
            data : PAID.data?.data.map(e => {
              return this.formatPrice(e.final_amount,"IDR")//this.formatPriceBasic(e.final_amount)
            })
          }
        }
        
      } catch (error) {
        return {
          data: []
        }
      }
      
    },
    async actionGetSalesunPaid() {
      try {
        const UnPAID = await 
          API
          .get("chart-dashboard/orders/unpaid")
  
        if (UnPAID) {
          console.log(UnPAID)
          return {
            data : UnPAID.data?.data.map(e => {
              return this.formatPrice(e.final_amount,"IDR") // this.formatPriceBasic(e.final_amount)
            })
          }
        }
        
      } catch (error) {
        return {
          data: []
        }
      }
    },
    formatPrice(value) {
      if (value) {
        return value;
      }

      return "0";
    },
  }
};
</script>
