var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-12 shadow-md p-2"
  }, [_c('h4', {
    staticClass: "h4 text-black text-center"
  }, [_vm._v(" Transaction")]), _c('apexchart', {
    attrs: {
      "type": "column",
      "height": "350",
      "options": _vm.chartOptionsSales1,
      "series": _vm.series
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }