<template>
  <div>    
    <apexchart
      type="bar"
      height="350"
      :options="chartReading"
      :series="ReadingSeries"
    />
  </div>
</template>

<script>
import moment from "moment";
import API from "../../plugins/http"
  export default {
    name:"PopularItemRead",
    data() {
      return{
        chartReading:{},
        ReadingSeries:[{ data: [],name:"Reading" }],
        items:[],
        duration:[],
        books:[]
      }
    },
    mounted() {
      this.actionGet()
    },
    methods: {
      actionGet(){
        let from = moment().format("YYYY-MM-DD")
        API.get('reading-durations/popular?from='+from).then(response => {
          this.items = response.data.data.item;
          this.duration = response.data.data.reading;
          this.actionDestructor()
        })
      },
      actionDestructor(){
        this.items.forEach(e => {
          let find = this.duration.find(d => parseInt(d.item_id) == e.id)
            this.books.push({
              name:e.name,
              isbn:e.gtin13,
              duration: find.duration,
              item_type:find.item_type,
            })
        });
        
        this.actionBuildChart()
      },
      actionBuildChart(){
        let book = this.books.map(i => i.name)
        this.books.sort((a,b) => (a.duration < b.duration) ? 1 : ((b.duration < a.duration) ? -1 : 0));
        let total = this.books.map(i => i.duration)
        
        this.ReadingSeries[0].data= total;
        this.chartReading = {
          plotOptions: {
            bar: {
              barHeight: '100%',
              distributed: true,
              horizontal: true,
            },
            
          },
          colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
            '#f48024', '#69d2e7'
          ],
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '11px',
            },
            formatter: value => this.formatMinutes(value)
          },
          tooltip: {
            theme: "dark",
            dataLabels: true,
            y: {
              formatter: value => this.formatMinutes(value)
            }
          },
          title: {
            text: "Popular Reading " + moment().format("MMMM YYYY") ,
            align: "left"
          },
          xaxis: {
            categories: book,
            align: "left",
            labels: {
              show: false,
              formatter: value => this.formatMinutes(value)
            }
          },
          yaxis: {
            labels: {
              show: true,
            },
          },
        };
      },
      formatPriceBasic(value) {
        let val = (value / 1).toFixed(0).replace(".", ",");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      },
      formatMinutes(data) {
        let menit = parseInt(data) / 3600
        if (menit > 59) {
          let hour = menit / 60
          return Math.round(hour) +' Hour'
        } else if (menit < 60) {
          return Math.round(menit) +' Minute'
        } else {
          return Math.round(data) + ' Second'
        }

      }
    },
    
  }
</script>

<style lang="scss" scoped>

</style>