<template>
  <div class="row">
    <div class="col-sm-12 shadow-md rounded">
      <apexchart
        type="donut"
        height="350"
        :options="chartOptionsSales1"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import API from "../../plugins/http";
export default {
  name: "LineChart",
  data() {
    return {
      series: [],
      chartOptionsSales1: {},
      data: [],
      datapaid: [],
      dataunpaid: []
    };
  },
  mounted() {
    this.actionGetSalesByPlatForm();
  },
  methods: {
    actionGetSalesByPlatForm() {
      let year = moment().format("YYYY-MM-DD");
      API
        .get("chart-dashboard/platform?year="+year)
        .then(response => {
          let data = response.data.data;
          this.data = data.map(c => {
            return c.count;
          });
          let labels = data.map(l => {
            return l.name;
          });
          this.series = this.data;
          this.chartOptionsSales1 = {
            title: {
              text: "Popular Transaction in Platform  from " + moment(year).format("MMMM YYYY"),
              align: "left"
            },
            // colors: ["#77B6EA", "#545454"],
            colors: ['#0060AF','#ff06dc','#59a5e3','#ffec2e', '#43d14f','#f86c6b'],
            labels: labels,
            dataLabels: {
              enabled: true,
              formatter: function(val) {
                return parseFloat(val).toFixed(2) + "%";
              }
            },
            plotOptions: {
              pie: {
                expandOnClick: true,
                donut: {
                  labels: {
                    show: true,
                    value: {
                      formatter: function(value) {
                        let val = (value / 1).toFixed(0).replace(".", ",");
                        return val
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                      }
                    }
                  }
                }
              }
            }
          };
        })
        .catch(() => {
          
        });
    },
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  }
};
</script>
