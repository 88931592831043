import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import VueSweetalert2 from "vue-sweetalert2";
import Multiselect from "vue-multiselect";
import vue2Dropzone from 'vue2-dropzone';

import "sweetalert2/dist/sweetalert2.min.css";
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "vue-multiselect/dist/vue-multiselect.min.css";
import "./plugins/Veevalidate";
import "./plugins/filter";
import "./plugins/GlobalMethods";
import "primevue/resources/themes/lara-light-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

import VueApexCharts from "vue-apexcharts";
import VCalendar from "v-calendar";
import PrimeVue from 'primevue/config';
import Calendar from 'primevue/calendar';

// Use v-calendar & v-date-picker components
Vue.use(VueApexCharts);
Vue.use(VCalendar);

Vue.component("apexchart", VueApexCharts);
Vue.component("vueDropzone", vue2Dropzone)
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueSweetalert2);
Vue.component("multiselect", Multiselect);
Vue.use(PrimeVue);
Vue.component('Calendar', Calendar);
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
