const LANGUAGE_CHOICES = {
  eng: 'English',
  ind: 'Indonesia',
  zho: 'Chinese',
  vie: 'Vietnam',
  tam: 'Tamil',
  tel: 'Telugu',
  mal: 'Malayalam',
  hin: 'Hindi',
  msa: 'Malay',
  por: 'Portuguese',
  tgl: 'Tagalog',
  tha: 'Thai',
  fil: 'Filipino',
  jpn: 'Japanese',
  deu: 'German',
  kor: 'Korean',
  spa: 'Spanish',
  rus: 'Russia',
};

const CONTENT_TYPE = {
  PDF: 'PDF',
  EPUB: 'EPUB',
  AUDIO: 'AUDIO',
};

const READING_DIRECTION = {
  'Read from Left to Right': 'left-to-right',
  'Read from Right to Left': 'right-to-left',
};

const PARENTAL_LEVEL_TYPE = {
  All: 1,
  '+3': 3,
  '+7': 4,
  '+12': 5,
  '+16': 6,
  '+17': 2,
};

const PRINTED_PRICE_ITEM = {
  INR: 'INR',
  USD: 'USD',
  MYR: 'MYR',
  PHP: 'PHP',
  SGD: 'SGD',
  IDR: 'IDR',
  HKD: 'HKD',
  AUD: 'AUD',
};

const ITEM_STATUS = {
  'Ready for Upload': 'ready for upload',
  Uploaded: 'uploaded',
  Published: 'ready for consume',
  // 'Un-published': 'not for consume',
  // 'Un-published Mcgrawhill': 'mcgrawhill content',
  Unpublished: 'not for consume',
  UnpublishedMcgrawhill: 'mcgrawhill content',
};

const ITEM_TYPES = {
  MAGAZINE: 'Magazine',
  BOOK: 'Book',
  NEWSPAPER: 'Newspaper',
  BONUSITEM: 'Bonus Item',
  AUDIOBOOK: 'Audio Book',
};

const UPLOAD_PROCESS_STATUS = {
  new: 1,
  processing: 2,
  complete: 10,
  failed: 11,
};

const BRAND_RELEASE_PERIOD = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  BIWEEKLY: 'biweekly',
  MONTHLY: 'monthly',
  BIMONTHLY: 'bimonthly',
  QUARTERLY: 'quarterly',
  SPECIAL: 'special',
};

const BRAND_TYPE = {
  EDITION: 'edition',
  MONTHLY: 'monthly',
};

const OFFER_STATUS = {
  NEW: 1,
  WAITING_FOR_REVIEW: 2,
  IN_REVIEW: 3,
  REJECTED: 4,
  APPROVED: 5,
  PREPARE_FOR_SALE: 6,
  READY_FOR_SALE: 7,
  NOT_FOR_SALE: 8,
};

const OFFER_TYPE = {
  SINGLE: 1,
  SUBSCRIPTIONS: 2,
  BUNDLE: 3,
  BUFFET: 4,
};

const OFFER_TYPE_NAME = {
  SINGLE: 'single',
  SUBSCRIPTIONS: 'subscription',
  BUNDLE: 'bundle',
  BUFFET: 'buffet',
};

const PLATFORM = {
  IOS: 1,
  ANDROID: 2,
  WINDOWS_PHONE: 3,
  WEB: 4,
};

const OFFER_SUBS_TYPE = {
  EDITION: 1,
  WEEKLY: 3,
  MONTHLY: 4,
};

const PREMIUM_OFFER_DURATION = {
  '1 Month': 30,
  '2 Months': 60,
  '3 Months': 90,
  '4 Months': 120,
  '5 Months': 150,
  '6 Months': 180,
  '12 Months': 365,
};

const IMG_STATIC_URL = {
  URL: 'https://ebooks.gramedia.com/ebook-statics/',
};

const IMG_URL_STATIC_PAYMENT = {
  URL: 'https://ebooks.gramedia.com/ebook-statics/payment-gateways/',
};

const URL_STATIC = {
  TEMPLATE_BULK_CREATE:
    'https://ebooks.gramedia.com/ebook-statics/bulk-uploader/cms-bulk-create-sample-staging.csv',
  TEMPLATE_BULK_CREATE_PUBLISHER:
    'https://ebooks.gramedia.com/ebook-statics/bulk-uploader/cms-bulk-create-sample-publisher-staging.csv',
  TEMPLATE_BULK_UPDATE:
    'https://ebooks.gramedia.com/ebook-statics/bulk-uploader/cms-bulk-update-sample.csv',
  TEMPLATE_BULK_UPLOAD_SINGLE:
    'https://ebooks.gramedia.com/ebook-statics/bulk-uploader/cms-bulk-upload-ax-single.xlsx',
  TEMPLATE_BULK_UPLOAD_PREMIUM:
    'https://ebooks.gramedia.com/ebook-statics/bulk-uploader/cms-bulk-upload-ax-premium.xlsx',
  EBOOK_COVER:
    process.env.VUE_APP_URL_EBOOK_COVER ??
    'https://ebooks.gramedia.com/ebook-covers/',
  EBOOK_PREVIEW:
    process.env.VUE_APP_URL_EBOOK_PREVIEW ??
    'https://s3-ap-southeast-1.amazonaws.com/ebook-previews/',
};

const IMG_URL_STATIC_BANNER = {
  URL: 'https://ebooks.gramedia.com/ebook-statics/banners/',
};

const IMG_URL_STATIC_OFFER = {
  URL: 'https://ebooks.gramedia.com/ebook-statics/offers/',
};

const IMG_URL_STATIC_PARTNER = {
  URL: 'https://ebooks.gramedia.com/ebook-statics/partner/',
};

const IMG_URL_STATIC_VENDOR = {
  URL: 'https://ebooks.gramedia.com/ebook-statics/organization/',
};
const IMG_URL_STATIC_AUTHOR = {
  URL: 'https://ebooks.gramedia.com/ebook-statics/authors/',
};
const VENDOR_STATUS = {
  NEW: 1,
  WAITING_FOR_REVIEW: 2,
  IN_REVIEW: 3,
  REJECTED: 4,
  APPROVED: 5,
  CLEAR: 6,
  BANNED: 7,
};

const VENDOR_TYPE = {
  Internal: false,
  External : true,
}

const BASE_CURRENCY = {
  USD: 1,
  IDR: 2,
  PTS: 3,
};

const PAYMENT_GROUP = {
  MIDTRANS: 'midtrans',
  E2PAY: 'e2pay',
  OTHERS: 'others',
};

const REPORT_SCOOP = {
  finance: 'finance',
  content: 'content',
};

const ORGANIZATION_STATUS_FORM = {
  ORGANIZATION_STATUS_NEW: 'New',
  ORGANIZATION_STATUS_APPROVED: 'Approved',
  ORGANIZATION_STATUS_CLEAR: 'Clear',
  ORGANIZATION_STATUS_BANNED: 'Banned',
};

const BANNER_OPEN_URL = 'open url';
const BANNER_BANNER_ONLY = 'banner only';
const BANNER_LANDING_PAGE = 'landing page';
// const BANNER_STATIC = 'static'
const BANNER_PROMO = 'promo';
// const BANNER_BRAND = 'brand'
// const BANNER_BUFFET = 'buffet'

const BANNER_SHOW_TYPES = {
  'Open url Banner': BANNER_OPEN_URL,
  'Banner Only': BANNER_BANNER_ONLY,
  'Landing page Banner': BANNER_LANDING_PAGE,
  'Promotion Banner': BANNER_PROMO,
};

const BANNER_ZONE_HOMEPAGE = 'homepage';
const BANNER_ZONE_MAGAZINE = 'magazine page';
const BANNER_ZONE_BOOK = 'book page';
const BANNER_ZONE_NEWSPAPER = 'newspaper page';
const BANNER_ZONE_PROMO = 'promo page';

const BANNER_ZONE = {
  'Display on Home Page': BANNER_ZONE_HOMEPAGE,
  'Display on Magazine Page': BANNER_ZONE_MAGAZINE,
  'Display on Book Page': BANNER_ZONE_BOOK,
  'Display on Newspaper Page': BANNER_ZONE_NEWSPAPER,
  'Display on Promo Page': BANNER_ZONE_PROMO,
};

const BANNER_SHOW_PRICE_SINGLE = 'single';
const BANNER_SHOW_PRICE_SUBS = 'subscription';

const BANNER_PRICE = {
  'Show Single Price Only': BANNER_SHOW_PRICE_SINGLE,
  'Show Subs Price Only': BANNER_SHOW_PRICE_SUBS,
};

const COST_GRAMEDIA = 1;
const COST_COMBINED = 2;
const COST_PARTNER = 3;

const PARTNER_PARTICIPATION = {
  'All Cost Absorb by Gramedia': COST_GRAMEDIA,
  'Gramedia and Partner 50:50 for Promo Cost': COST_COMBINED,
  'All Cost Absorb by Partner': COST_PARTNER,
};

const OFFER_DISCOUNT_RULE_AMOUNT = 1;
const OFFER_DISCOUNT_RULE_PERCENTAGE = 2;
const OFFER_DISCOUNT_RULE_FLUSH = 3;

const OFFER_DISCOUNT_RULE = {
  'Cut by Amount.': OFFER_DISCOUNT_RULE_AMOUNT,
  'Cut by Percentage.': OFFER_DISCOUNT_RULE_PERCENTAGE,
  'Flush Price.': OFFER_DISCOUNT_RULE_FLUSH,
};

const TRIALS = {
  '1 days': 1,
  '2 days': 2,
  '3 days': 3,
  '4 days': 4,
  '5 days': 5,
  '6 days': 6,
  '7 days': 7,
  '8 days': 8,
  '9 days': 9,
  '10 days': 10,
  '11 days': 11,
  '12 days': 12,
  '13 days': 13,
  '14 days': 14,
};

const PLATFORM_IOS = 1;
const PLATFORM_ANDROID = 2;
// const PLATFORM_WINDOWS_PHONE = 3
const PLATFORM_GETSCOOP = 4;

const PLATFORM_PROMO = {
  IOS: PLATFORM_IOS,
  Android: PLATFORM_ANDROID,
  'Web Store': PLATFORM_GETSCOOP,
};

const ANZ_BIN = [462239, 456462, 483561, 436773];
const BCA_BIN = [
  455632,
  445377,
  472646,
  472647,
  431657,
  477377,
  522990,
  540912,
  455633,
  543248,
  515291,
  541322,
  469151,
];
const BCA_BLU_BIN = [539371];
const BRI_BIN = [
  552002,
  468740,
  553479,
  518856,
  518828,
  522184,
  436502,
  547582,
];
const BNI_BIN = [
  410504,
  410505,
  517643,
  466573,
  466574,
  531857,
  457512,
  522028,
  518446,
  542640,
  451249,
  516919,
  529721,
  548415,
  524609,
  526415,
  519893,
  526422,
  526423,
  526426,
  524125,
  436583,
  536021,
  510055,
  426097,
  426098,
  522615,
  536184,
  548988,
  471293,
  521983,
];
const BTN_BIN = [421570, 485447];
const BTPN_BIN = [466160];
const BUKOPIN_BIN = [523940, 526853, 421167, 421168, 489781, 552695];
const CIMB_BIN = [
  428416,
  542466,
  479446,
  540174,
  459920,
  459921,
  548116,
  548117,
  528919,
  489503,
  528674,
  462888,
  456878,
  456879,
  552115,
  530487,
  537656,
  441659,
  467908,
  457941,
  457942,
  437599,
  542441,
  552810,
  518303,
  519603,
  522866,
  533619,
  557692,
];
const CITIBANK_BIN = [
  453248,
  542177,
  454178,
  454179,
  552228,
  426569,
  425864,
  456377,
  552042,
  461725,
  432207,
  470544,
  461778,
  526899,
  542550,
  438679,
  540184,
  414009,
  558720,
  555163,
  414746,
];
const DANAMON_BIN = [
  432449,
  552239,
  552338,
  542260,
  521558,
  557791,
  540731,
  456798,
  456799,
];
const DBS_BIN = [
  430981,
  411911,
  541069,
  541070,
  528912,
  458769,
  512021,
  409636,
  405542,
  526471,
  541616,
  455621,
  437450,
  437451,
  525644,
  460238,
  437456,
  522846,
  552038,
  415735,
  415736,
  462845,
];
const HSBC_BIN = [
  447211,
  400934,
  518535,
  540041,
  409675,
  461772,
  492111,
  403409,
  518323,
  483574,
  454493,
  518494,
];
const MANDIRI_BIN = [
  461699,
  461700,
  413719,
  468749,
  445076,
  413718,
  437527,
  437528,
  557338,
  434075,
  512676,
  524325,
  409766,
  490283,
  490284,
  421197,
  483795,
  483796,
  425945,
  400479,
  414931,
  489594,
];
const MAYBANK_BIN = [
  442373,
  442374,
  404776,
  515595,
  428332,
  496623,
  552008,
  545298,
  545299,
  464987,
  426013,
  520037,
];
const MEGA_BIN = [
  420192,
  458785,
  420194,
  426211,
  524261,
  478487,
  431226,
  420191,
  472670,
  489087,
];
const MNC_BIN = [512630, 432443, 426750];
const PERMATA_BIN = [
  498853,
  464005,
  528872,
  454633,
  542167,
  426254,
  489385,
  549846,
  461785,
  544741,
  540889,
  518943,
  554302,
  471295,
];
const PANIN_BIN = [437700, 437701];
const SINARMAS_BIN = [489372, 489374];
const STANDARD_CHARTERED_BIN = [
  403265,
  549834,
  442394,
  430092,
  552302,
  558959,
  544304,
  553074,
  552339,
  514934,
  493496,
  493497,
  493498,
  493499,
  451196,
  451197,
];
const UOB_BIN = [421920, 512765, 472629, 519311];
const OCBC_NISP_BIN = [464583, 524169, 452419, 540012];
const DISCOUNT_OFFER = 1;
const DISCOUNT_ORDER = 2;
const DISCOUNT_PG_OFFER = 3;
const DISCOUNT_PG_ORDER = 4;
const DISCOUNT_CODE = 5;

const DISCOUNT_TYPES = {
  'Discount Offers': DISCOUNT_OFFER,
  'Discount Code': DISCOUNT_CODE,
  'Discount Order': DISCOUNT_ORDER,
  'Discount Payment Gateway Offer': DISCOUNT_PG_OFFER,
  'Discount Payment Gateway Order': DISCOUNT_PG_ORDER,
};
const PAYMENT_GATEWAY = {
  'Apple iTunes': 1,
  'Go Pay': 32,
  'Virtual Account BNI': 31,
  'Virtual Account Permata': 28,
  'Virtual Account BCA': 29,
  'Virtual Account Mandiri': 30,
  'Google In App Billing': 15,
  'Credit Card': 12,
  'GD Point': 9,
  'Link Aja': 33,
  'CIMB Clicks': 35,
  'CIMB GoMobile': 36,
  'CIMB RekPon': 37,
  'Shopee Pay': 38,
  'Qris Payment': 50,
  'Free Purchase': 3,
  'SCOOP Wallet': 24,
};

const ACTIVE_BIN = [
  ...STANDARD_CHARTERED_BIN.map((val) => ({
    value: `${val}`,
    name: `${val} - STANDARD_CHARTERED`,
  })),
  ...OCBC_NISP_BIN.map((val) => ({
    value: `${val}`,
    name: `${val} - OCBC NISP`,
  })),
  ...CITIBANK_BIN.map((val) => ({
    value: `${val}`,
    name: `${val} - CITIBANK`,
  })),
  ...BUKOPIN_BIN.map((val) => ({ value: `${val}`, name: `${val} - BUKOPIN` })),
  ...MAYBANK_BIN.map((val) => ({ value: `${val}`, name: `${val} - MAYBANK` })),
  ...BNI_BIN.map((val) => ({ value: `${val}`, name: `${val} - BNI` })),
  ...CIMB_BIN.map((val) => ({ value: `${val}`, namet: `${val} - CIMB` })),
  ...BCA_BIN.map((val) => ({ value: `${val}`, name: `${val} - BCA` })),
  ...BCA_BLU_BIN.map((val) => ({ value: `${val}`, name: `${val} - BCA BLU` })),
  ...DANAMON_BIN.map((val) => ({ value: `${val}`, name: `${val} - DANAMON` })),
  ...MANDIRI_BIN.map((val) => ({ value: `${val}`, name: `${val} - MANDIRI` })),
  ...MEGA_BIN.map((val) => ({ value: `${val}`, name: `${val} - MEGA` })),
  ...UOB_BIN.map((val) => ({ value: `${val}`, name: `${val} - UOB` })),
  ...BTPN_BIN.map((val) => ({ value: `${val}`, name: `${val} - BTPN` })),
  ...DBS_BIN.map((val) => ({ value: `${val}`, name: `${val} - DBS` })),
  ...ANZ_BIN.map((val) => ({ value: `${val}`, name: `${val} - ANZ` })),
  ...SINARMAS_BIN.map((val) => ({
    value: `${val}`,
    name: `${val} - SINARMAS`,
  })),
  ...BTN_BIN.map((val) => ({ value: `${val}`, name: `${val} - BTN` })),
  ...BRI_BIN.map((val) => ({ value: `${val}`, name: `${val} - BRI` })),
  ...HSBC_BIN.map((val) => ({ value: `${val}`, name: `${val} - HSBC` })),
  ...MNC_BIN.map((val) => ({ value: `${val}`, name: `${val} - MNC` })),
  ...PERMATA_BIN.map((val) => ({ value: `${val}`, name: `${val} - PERMATA` })),
  ...PANIN_BIN.map((val) => ({ value: `${val}`, name: `${val} - PANIN` })),
];

const DISCOUNT_PREDEFINED_ALL_MAGAZINE = 1;
const DISCOUNT_PREDEFINED_ALL_BOOK = 2;
const DISCOUNT_PREDEFINED_ALL_NEWSPAPERS = 3;
const DISCOUNT_PREDEFINED_ALL_OFFERS = 4;
const DISCOUNT_PREDEFINED_ALL_SINGLE = 5;
const DISCOUNT_PREDEFINED_ALL_SUBSCRIPTIONS = 6;
const DISCOUNT_PREDEFINED_MAGAZINE_SINGLE = 7;
const DISCOUNT_PREDEFINED_MAGAZINE_SUBSCRIPTIONS = 8;
const DISCOUNT_PREDEFINED_MAGAZINE_NEWSPAPER = 9;
// const DISCOUNT_PREDEFINED_ITEM_IN_VENDORS = 10;
const DISCOUNT_PREDEFINED_ALL_MAGAZINE_VENDORS = 11;
const DISCOUNT_PREDEFINED_ALL_BOOK_VENDORS = 10;
const DISCOUNT_PREDEFINED_ALL_NEWSPAPERS_VENDORS = 12;
// const DISCOUNT_PREDEFINED_HARPER_COLLINS = 467;

const DISCOUNT_PREDEFINED_GROUP = {
  'All Magazine': DISCOUNT_PREDEFINED_ALL_MAGAZINE,
  'All Book': DISCOUNT_PREDEFINED_ALL_BOOK,
  'All Newspaper': DISCOUNT_PREDEFINED_ALL_NEWSPAPERS,
  'All Offers': DISCOUNT_PREDEFINED_ALL_OFFERS,
  'All Single Magazine and Book': DISCOUNT_PREDEFINED_ALL_SINGLE,
  'All Subscriptions Magazine and Newspapers': DISCOUNT_PREDEFINED_ALL_SUBSCRIPTIONS,
  'Magazine Single Only': DISCOUNT_PREDEFINED_MAGAZINE_SINGLE,
  'Magazine Subscription Only': DISCOUNT_PREDEFINED_MAGAZINE_SUBSCRIPTIONS,
  'All Magazine and Newspapers': DISCOUNT_PREDEFINED_MAGAZINE_NEWSPAPER,
  'All Magazine In Vendors': DISCOUNT_PREDEFINED_ALL_MAGAZINE_VENDORS,
  'All Book In Vendors': DISCOUNT_PREDEFINED_ALL_BOOK_VENDORS,
  'All Newspaper In Vendors': DISCOUNT_PREDEFINED_ALL_NEWSPAPERS_VENDORS,
};

const ROLES = {
  SUPER_ADMIN: 1,
  INTERNAL_ADMIN: 2,
  INTERNAL_MARKETING: 3,
  INTERNAL_FINANCE: 4,
  ORGANIZATION_SPECIFIC_ADMIN: 5 /* PUBLISHER ACCESS ROLE */,
  ORGANIZATION_SPECIFIC_MARKETING: 6,
  ORGANIZATION_SPECIFIC_FINANCE: 7,
  GUEST_USER: 8,
  UNVERIFIED_USER: 9,
  VERIFIED_USER: 10,
  SANDBOX_USER: 11,
  PUBLIC_USER: 12,
  RESOURCE_SERVER: 13,
  INTERNAL_DATA_ENTRY: 14,
  ORGANIZATION_SPECIFIC_DATA_ENTRY: 16,
  INTERNAL_DEVELOPER: 17,
  CAFE_USER: 18,
  BANNED_USER: 19,
  SALES_PROMOTION_GIRL: 20,
  INTERNAL_ADMIN_EPERPUS: 21,
  ORGANIZATION_ADMIN_EPERPUS: 22,
  CUSTOMER_SUPPORT: 23,
  ADMIN_CUSTOMER_SUPPORT: 24,
  PUBLISHER_EPERPUS: 25,
  DEVOPS_ADMIN: 26,
  DELETED: 32,
  KAWANPEDIA_USER: 31,
};

const ALLOWED_ROLE_WITHOUT_VENDOR = [
  ROLES.SUPER_ADMIN,
  ROLES.INTERNAL_ADMIN,
  ROLES.INTERNAL_MARKETING,
  ROLES.INTERNAL_FINANCE,
  ROLES.INTERNAL_DATA_ENTRY,
  ROLES.INTERNAL_DEVELOPER,
  ROLES.CUSTOMER_SUPPORT,
  ROLES.ADMIN_CUSTOMER_SUPPORT,
];
const SOURCE_CURRENCY = {
  GOOGLE: 'Google',
  YAHOO: 'Yahoo',
  OTHERS: 'Others',
  FINANCE_ADMIN: 'Finance Admin',
};
const CLIENT_ID = {
  SCOOP_IOS: 1,
  SCOOP_ANDROID: 2,
  SCOOP_WEB: 7,
  SCOOP_PORTAL: 67,
  GRAMEDIA: 82,
};
const ORDERSTATUS = {
  NEW: 10001,
  WAITING_PAYMENT: 20001,
  PROCESS: 20002,
  PAID: 90000,
  CANCEL: 50000,
  ERROR: 50001,
  DELIVERY_ERROR: 50002,
  DENIED: 50003,
  EXPIRE: 50004,
  REFUND: 40003,
};

const VIOLATION_STATUS = {
  VIOLATION_SCREEN: 'Screenshots Violation',
  VIOLATION_HACK: 'Hackers Violation',
  VIOLATION_SPAM: 'Spamming Violation',
  VIOLATION_SARA: 'SARA Violation',
  VIOLATION_REFERRAL: 'Referral Violation',
};

const TIER_TYPE = {
  TIER_NON_CONSUMABLE: 1,
  TIER_CONSUMABLE: 2,
};

const TIER_TYPE_LABEL = {
  TIER_NON_CONSUMABLE: 'Non Consumable Tier',
  TIER_CONSUMABLE: 'Consumable Tier',
};

const TIER_TYPE_OPTION = Object.keys(TIER_TYPE).map((key) => ({
  value: TIER_TYPE[key],
  text: TIER_TYPE_LABEL[key],
}));

const APPSTRORE_NOTIFICATION_TYPE = {
  EXPIRED: 'EXPIRED',
  DID_RENEW: 'DID_RENEW',
  DID_CHANGE_RENEWAL_STATUS: 'DID_CHANGE_RENEWAL_STATUS',
  DID_FAIL_TO_RENEW: 'DID_FAIL_TO_RENEW',
  DID_CHANGE_RENEWAL_PREF: 'DID_CHANGE_RENEWAL_PREF',
  PRICE_INCREASE: 'PRICE_INCREASE',
  REFUND: 'REFUND',
  REFUND_DECLINED: 'REFUND_DECLINED',
  SUBSCRIBED: 'SUBSCRIBED',
};

const PUBLISHER_PAYMENT_CURRENCY = {
  'Indonesian Rupiah': 'IDR',
  'Singapore Dollar': 'SGD',
  'US Dollar': 'USD',
};

const ITEM_STATUS_REGISTER_AX = {
  Submitted: 0,
  Approved: 1,
  Released: 2,
  Rejected: 3,
};

const USER_ROLE = {
  UNVERIFIED: 9,
  VERIFIED: 10,
  BANNED: 19,
};

export default {
  LANGUAGE_CHOICES,
  CONTENT_TYPE,
  READING_DIRECTION,
  PARENTAL_LEVEL_TYPE,
  PRINTED_PRICE_ITEM,
  ITEM_STATUS,
  ITEM_TYPES,
  UPLOAD_PROCESS_STATUS,
  BRAND_RELEASE_PERIOD,
  BRAND_TYPE,
  OFFER_STATUS,
  OFFER_TYPE,
  OFFER_TYPE_NAME,
  PLATFORM,
  ROLES,
  OFFER_SUBS_TYPE,
  PREMIUM_OFFER_DURATION,
  IMG_STATIC_URL,
  IMG_URL_STATIC_PAYMENT,
  URL_STATIC,
  IMG_URL_STATIC_BANNER,
  IMG_URL_STATIC_OFFER,
  IMG_URL_STATIC_PARTNER,
  VENDOR_STATUS,
  VENDOR_TYPE,
  BASE_CURRENCY,
  PAYMENT_GROUP,
  REPORT_SCOOP,
  ORGANIZATION_STATUS_FORM,
  BANNER_SHOW_TYPES,
  BANNER_ZONE,
  BANNER_PRICE,
  TRIALS,
  PAYMENT_GATEWAY,
  PARTNER_PARTICIPATION,
  OFFER_DISCOUNT_RULE,
  PLATFORM_PROMO,
  ACTIVE_BIN,
  DISCOUNT_PREDEFINED_GROUP,
  IMG_URL_STATIC_VENDOR,
  ALLOWED_ROLE_WITHOUT_VENDOR,
  SOURCE_CURRENCY,
  CLIENT_ID,
  IMG_URL_STATIC_AUTHOR,
  ORDERSTATUS,
  VIOLATION_STATUS,
  DISCOUNT_TYPES,
  TIER_TYPE,
  TIER_TYPE_LABEL,
  TIER_TYPE_OPTION,
  APPSTRORE_NOTIFICATION_TYPE,
  PUBLISHER_PAYMENT_CURRENCY,
  ITEM_STATUS_REGISTER_AX,
  USER_ROLE,
};
