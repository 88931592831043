import API from "../plugins/http";
const Newspaper = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    errorMessage: '',
    successMessage: '',
    refresh: false,
  },
  getters: {
    Newspaper: (state) => state.items,
    NewsPaperSales: (state) => {
      return state.items.filter((i) => i.is_internal_content === false);
    },
  },
  actions: {
    async fetchNewsPaper({ commit }, props) {
      try {
        commit("setLoading", true);
        const response = await API.get(
          `items?item_type=newspaper&page=${props.page}&limit=${props.limit}`
        );
        commit("setItems", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
        commit("setError", true);
      }
    },
    async fetchNewsPaperById({ commit }, props) {
        commit("setLoading", true);
        try {
        const response = await API.get(`items/${props.id}`);
        commit("setItems", response.data.data?.rows);
        commit("setItem", response.data.data?.rows[0]);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
        commit("setError", true);
      }
    },
    async searchNewsPaper({ commit }, props) {
      try {
        commit('setError', false);
        commit("setLoading", true);
        const response = await API.get(
          `items-search/newspaper`, {
            params: props,
          }
        );
          commit("setItems", response.data.data.rows);
          commit("setRows", response.data.data.count);
          commit("setLoading", false);
        } catch (error) {
          commit("setLoading", false);
          commit("setError", true);
        }
    },

    async postNewspaper({ commit }, payload) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      commit('setLoading', true);
      try {
        const response = await API.post(`items/newspaper`, payload);
        commit('setRefresh', false);
        if (response.status == 201) {
          commit('setSuccessMessage', "Success Created");
          commit('setItem', response.data?.data?.rows[0]);
        }
        commit('setLoading', false);
      } catch (error) {
        const response = error.response;
        let errMsg = "Error, please try again";
        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
          errMsg = response.statusText;
          errMsg += ' REQUIRED ';
          errMsg += (response.data.errors ?? []).map(item => item.param).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setError', true);
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
    },

    async uploadAsset({ commit }, payload) {
      commit('setRefresh', false);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      commit('setLoading', true);
      try {
        const formData = new FormData();
        formData.append('file_content', payload.file_content);
        formData.append('id', payload.id);
        const response = await API.post(`upload`, formData);
        if (response.status == 200) {
          commit('setSuccessMessage', "Success Uploaded");
          commit('setRefresh', true);
        }
      } catch (error) {
        const response = error.response;
        let errMsg = "Error, please try again";

        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
          errMsg = response.statusText;
          errMsg += ' REQUIRED ';
          errMsg += (response.data.errors ?? []).map(item => item.param).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setLoading', false);
        commit('setError', true);
        commit('setErrorMessage', errMsg);
      }
    },

    async updateNewspaper({ commit }, payload) {
      commit('setRefresh', false);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      commit('setLoading', true);
      try {
        const response = await API.put(`items/${payload.item_id}`, payload);
        if (response.status == 201) {
          commit('setSuccessMessage', "Success Updated");
          commit('setRefresh', true);
        }
        commit('setLoading', false);
      } catch (error) {
        const response = error.response
        let errMsg = "Error, please try again";

        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
          errMsg = response.statusText;
          errMsg += ' REQUIRED ';
          errMsg += (response.data.errors ?? []).map(item => item.param).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setLoading', false);
        commit('setError', true);
        commit('setErrorMessage', errMsg);
      }
    },
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setRefresh(state, data) {
      state.refresh = data;
    },
  },
};

export default Newspaper;
